var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container d-flex h-100" }, [
    _c(
      "div",
      {
        staticClass:
          "w-100 justify-content-center align-self-center text-center",
      },
      [
        _vm.currentSlide === "domestic_registration"
          ? _c("select-domestic-registration", {
              on: { next: _vm.setCurrentSlide },
            })
          : _vm.currentSlide === "formation_info"
          ? _c("select-company-formed", { on: { next: _vm.setCurrentSlide } })
          : _vm.currentSlide === "select_starting_point"
          ? _c("select-company-starting-point")
          : _vm.currentSlide === "select_stage"
          ? _c("select-stage")
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }